.App {

	h1 {
		padding-top: 80px;
	}
}

.tags {
	text-align: center;
	margin-top: -5%;
	padding-bottom: 2%;
	background-color: transparent;
}

.tag {
	outline: none;
	border: none;
	color: #eee;
	margin: 0px 10px;
	background-color: transparent;
	cursor: pointer;
}

.active {
	font-weight: bold;
}
.container {
	width: 100%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(4, 1fr) !important;
	gap: 10px;
}

.image-card {
	padding: 5px;
	margin: 5px;
	border-radius: 2px;
}

.image {
	width: 100%;
}