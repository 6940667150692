body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

form {
  max-width: 80%;
  margin-left: 10%;
  margin-top: 10%;
  padding-bottom: 10%;
}

h1 {
  font-weight: 100;
  color: white;
  text-align: center;
}

.form {
  background: #0e101c;
  max-width: 100%;
  margin: 0 auto;
}

p {
  color: #bf1650;
}

p::before {
  display: inline;
  content: "⚠ ";
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

input[type="submit"] {
  background: rgb(144, 242, 252);
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
}

input[type="submit"]:hover {
  background: rgb(144, 242, 252);
}

input[type="button"]:active,
input[type="submit"]:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  width: 600px;
  opacity: 0.4;
}

input[type="button"]:hover {
  transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
}

.message-chars-left {
  width: 100%;
  text-align: left;
  color: rgb(144, 242, 252);
}

.status-message {
  opacity: 0;
}.success {
  opacity: 1;
  color: green;
}.failure {
  opacity: 1;
  color: red;
}