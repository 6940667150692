

$large: "only screen and (max-width: 1370px)";
$medium: "only screen and (max-width: 900px)";
$small: "only screen and (max-width: 430px)";

@mixin white-gradient {
	background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);

  @media #{$medium} {
    background: linear-gradient(to right,  rgba(255,255,255,0.1) 0%,rgba(255,255,255,0) 100%);
   }
}

$animationSpeed: 40s;

// Animation
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 5.7))}
}


// Styling
.slider {
  display: flex;
  align-items: center;
	background: transparent;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: absolute;
	width: 160%;
  
  @media #{$medium} {
    margin-left: 15%;
    width: 115%;
  }
  
  &::before,
  &::after {
    @include white-gradient;
    content: "";
		height: 100px;
		position: absolute;
		width: 100px;
		z-index: 2;
	}
	
	&::after {
    right: 0;
		top: 0;
		transform: rotateZ(180deg);
	}
  
	&::before {
    left: 0;
		top: 0;
	}
	
	.slide-track {
		animation: scroll $animationSpeed linear infinite;
		display: flex;
		width: calc(250px * 7);
	}
	
	.slide {
    margin: 1%;
		height: 100px;
		width: 250px;
	}
}