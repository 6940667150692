$large: "only screen and (max-width: 1370px)";
$medium: "only screen and (max-width: 900px)";
$small: "only screen and (max-width: 430px)";

.App {
  margin-top: -5%;
  padding-bottom: 10%;

  h1 {
    margin-bottom: 10%;
  }

  @media #{$medium} {
    h1{
      font-size: 24px;
      padding-bottom: 10%;
    }
    h3 {
      padding-bottom: 10%;
    }
  }
}

.gallery-container {
	width: 100%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr)!important;
}

.lcard {
  background: transparent;
  margin-left: 10%;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
	border-radius: 2px;

  a {
    margin-top: 5px;
    margin-left: 5px;
  }

  @media #{$medium} {
    img {
      width: 95% !important;
      margin-top: 80px;
    }
  }
}

.rcard {

  margin-right: 10%;
  background: transparent;
	border-radius: 2px;

  p {
    text-align: left;
    color: white !important;
    font-size: 20px;
    font-weight: lighter;
    &::before {
      content: '' !important;
    }

    @media #{$medium} {
      font-size: 16px;
      padding-bottom: 30%;
    }
  }
}