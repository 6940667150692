$main-back: rgb(16, 16, 16);
$main-font-col: rgb(246, 246, 246);
$headerNav-back: rgba(169, 169, 169, 0.98);
$headerHr-back: rgba(144, 242, 252, 0.1);
$third-header-size: 25px;
$second-header-size: 15px;
$main-nav-col: rgb(46, 46, 46);
$sneakyLinear: linear-gradient(
    90deg,
    rgba(144, 242, 252, 0.3) 0%,
    rgba(184, 184, 184, 0.2) 35%,
    rgba(2, 0, 36, 0.8) 100%
  );
$circularLi: linear-gradient(.51turn, blue, 0.9%, rgba(16, 16, 16, 0.9));
$varela-grey: rgb(169, 169, 169);
$varela-blue: rgb(144, 242, 252);
$link-hover-col: rgb(144, 242, 252);
$caption-size: 34px;
$header-back: rgb(216, 216, 216);
$main-pic: $header-back url("/video/Pexels Videos 2675511.mp4") 0 bottom no-repeat;
$footer-font-col: hsl(0, 0%, 35%);
$footer-caption-col: hsl(0, 0%, 19%);
$arial: Arial, Helvetica, sans-serif;
$oswald: "Oswald", Arial, Helvetica, sans-serif;
$button-col: hsl(0, 0%, 56%);

// BREAKPOINTS

$large: "only screen and (max-width: 1370px)";
$medium: "only screen and (max-width: 900px)";
$small: "only screen and (max-width: 430px)";

@keyframes hovering {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(3px);
  }
  40% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0px);
  }
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  border: none;
  font-family: $oswald;
  color: $varela-blue;
}

a {
  color: $main-font-col !important;
  text-decoration: none;
  transition: all .2s ease;
  display: inline-block;
  position: relative;

  &::after {
      content: '';
      background: transparent;
      position: absolute;
      height: 3px;
      bottom: -3px;
      left: 0;
      right: 0;
      transition: all .8s ease;
  }

  &:hover {

      &::after {
          background: transparent;
      }
  }
}

html, body {
  height: 100%;
}

.backgroundVid {
  max-height: 80vh;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1 {
  font-size: $caption-size;
  margin-bottom: 10px;

  @media #{$small} {
      font-size: $second-header-size; 
  }
}

h2 {
  font-size: $second-header-size;
}

h3 {
  font-size: $third-header-size;
}

input[type=text], select {    
  border: none;
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
  font-size: 15px;
  box-sizing: border-box;
}

#root {
  background: $main-back;
  min-height: 100%;
}

.home {
  &__hero {
    height: 100vh;
    background: $circularLi;
    
    @media #{$small} {
      background-position: 0 0;
      text-decoration: none;

    }
  }

  &__caption {
    font-size: $third-header-size;
    color: rgb(240, 242, 242);
    transition: all 0.3s ease;
    z-index: 99;
    cursor: pointer;

    @media #{$large} {
      font-size: $third-header-size;
      margin-top: 7em;

      &:hover {
        color: $link-hover-col;
      }
    }

    @media #{$small} {
      font-size: $second-header-size;
      
    }

    &:hover {
      color: $link-hover-col;
    }
  }

  &__caption-hint {
    text-align: right;
    font-size: 10px;
    color: rgb(240, 242, 242,);
    // z-index: 99;

    @media #{$medium} {
      margin-top: 15vh;
    }
  }

  &__scroll-down {
    width: 30px;
    background: url("/img/scroll.png") no-repeat;
    padding-bottom: 23%;
    animation: hovering 1s ease-in-out infinite;
    cursor: pointer;
    z-index: 99;

    @media #{$small} {
      font-size: 24px;
      margin-top: -20vh;
    }

    &:hover {
      background: url("/img/scrollHover.png") no-repeat ;

      @media #{$medium} {
        background: url("/img/scrollHover.png") no-repeat center !important;
  
      }
    }
  }
}

.ColoredHr0 {
  margin-top: 0;
  height: 10px;
  width: 70%;
  background-color: $headerHr-back;
}

.ColoredHr1 {
  margin-top: 0.5%;
  height: 20px;
  width: 80%;
  background-color: $main-font-col;
}

.ColoredHr {
  margin-top: 0.5%;
  height: 40px;
  width: 90%;
  background-color: $link-hover-col;
}

.demo-pieuvre {
  height: 800px;
  width: 90%;
  padding-bottom: 30px;
  
  &__schema{
    z-index: -1;
    margin-top: -200px;
    margin-left: 20%;
    text-align: center;
    height: 100%;
    background: url("/img/schempieuvre.png") no-repeat ;
  }
  
  &__pic {
    z-index: 99;
    margin-top: 5%;
    margin-left: 5%;
    border: solid 3px $link-hover-col;
    height: 33%;
    width: 17%;
    text-align: left;
    background: url("/img/picpieuvre.png") no-repeat ;
  }
  &__info{
    margin-top: -400px;
    font: 3em sans-serif;
    text-align: right;
  }

}



.wrapper {
  margin: 0 auto;
  max-width: 1364px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding: 180px ;
  box-sizing: border-box;
  flex-wrap: wrap;

  @media #{$large} {
    padding: 60px 20px;
  }


  &--header {
    flex-direction: row;
    align-items: flex-start;
    padding: 0;

    @media #{$large} {
      height: 3em;
      padding: 4px 20px;
    }

    @media #{$small}{
      padding: 4px 20px;
    }
  }


  &--footer {
    
    flex-direction: row;
    margin-left: 60%;
    align-items: flex-start;
    padding: 0;
  }

  &--footer {
    @media #{$large} {
      margin-left: 20px;
      z-index: 99;
    }

    @media #{$medium} {
      flex-direction: column;
      align-items: center;
      z-index: 99;
    }
  }
}

.logo {
  width: 280px;
  height: 140px;
  background: url("/img/HeroTitle.png") no-repeat;
  z-index: 1;
}

.flogo {
  width: 280px;
  height: 130px;
  background: url("/img/FooterTitle.png") repeat no-repeat;
  z-index: 1;
}

.main-nav {
  display: flex;
  width: 65%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
  flex-grow: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.5em;

  @media #{$medium} {
    flex-grow: 2;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &--header{
    font-size: 21px;
    margin-top: 26px;
    justify-content: flex-start;

    @media#{$medium} {
      display: none;
    }

    >a {
      margin-right: 85px;
    }
  }

  &--sidebar {
    padding-top: 22px;
    background-color: $headerNav-back;
    width: 100%;
    
  }

  &__item {
    text-transform: uppercase;
    display: inline-block;
    color: $main-font-col;

    @media#{$medium} {
      margin: 0 0 20px 0;
    }

    @media#{$small} {
      font-size: 16px;
    }

    &.active {
      
      &::after {
        background: $link-hover-col;
      }
    }
  }
}

.footer {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  min-height: 356px;
  background: $header-back;
  padding: 70px 0 10px;
  box-sizing: border-box;
  background-position: top;
  position: relative;

  @media #{$medium} {
    background: $header-back;
  }

  @media #{large} {
    display: flex;
    flex-flow: row wrap;
    background: $header-back;
    justify-content: space-around;
  }

  &__logos {
    padding-top: 16%;
    margin-left: -300%;
    width: 40%;
    display: flex;
    flex-direction: column;
    min-width: 300px;


    @media #{$medium} {
      margin-left: 3%;
    }
  }


  &__partners{
    padding-top: 1%;
    margin-left: 36.8%;
    position: absolute;

    flex: 1 auto;

    @media #{$medium} {
      margin-top: 55%;
      margin-left: 5%;

      h3 {
        text-align: left;
        margin-left: 15%;
      }
    }
  }

  &__copy {
    padding-top: 18%;
    margin-left: 40%; 
    position: absolute;
    text-align: center;
    font-size: 10px;
    font-family: $arial;
    color: $footer-font-col;

    @media #{$medium} {
      margin-left: 12%;
      margin-top: 138%;
      padding-top: 20%;
      padding-bottom: 5%;
    }
  }

  &__social {
    position: absolute;
    margin-left: 74%;
    padding-top: 4.5%;
    width: 350px;
    min-width: 250px;

    @media #{$medium} {
      position: relative;
      margin-left: 16%;
      text-align: left;
      padding-top: 70%;
      padding-bottom: 30%;
    }
  }

  .logo {
    display: flex;
    margin-top: 0;
  }

  h3 {
    color: $footer-caption-col;
    margin-bottom: 18px;
    line-height: 24px;
  }
}

.contactForm {
  display: flex;
  flex-direction: colulmn;

  @media #{$medium} {
    align-items: center;
  }

  input[type="text"],
  select {
    width: 400px;
    margin-bottom: 15px;

    @media #{$large} {
      width: 100%;
    }

    @media #{$small} {
      width: 48%;
    }
  }

  input[type=checkbox] {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    margin-left: 0;

    &::after {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      background: $main-font-col;
      border: 1px solid $footer-font-col;
    }
  }

  &__purposes__title {
    display: flex;
    font-size: 12px;
    color: $footer-font-col;
    font-family: $arial;
    width: 400px;
    line-height: 17px;
    margin-bottom: 30px;

    @media #{$large} {
      width: 100%;
    }
  }

  &__devis {
    display: flex;
    font-size: 12px;
    color: $footer-font-col;
    font-family: $arial;
    width: 400px;
    line-height: 17px;
    margin-bottom: 30px;

    @media #{$large} {
      width: 100%;
    }
  }

  &__entrepreneur {
    display: flex;
    font-size: 12px;
    color: $footer-font-col;
    font-family: $arial;
    width: 400px;
    line-height: 17px;
    margin-bottom: 30px;

    @media #{$large} {
      width: 100%;
    }
  }
}

.prim-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background:$main-nav-col;
  border: none;
  color: $main-font-col;
  font-family: $oswald;
  text-transform: uppercase;
  font-size: 21px;
  width: 195px;
  height: 45px;
  border-radius: 30%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #fafafa;
    color: cornflowerblue;
  }

  margin-left: 2%;
}

.social-icon {
  width: 33px;
  height: 33px;
  margin-right: 20px;
}

.socials {
  width:100%;
  display: flex;
  flex-grow: 4;
  text-align: right;

  &__facebook {
    @extend .social-icon;
    background: url("/img/facebook.svg") center no-repeat;
    
    &:hover {
      background: url("/img/bfacebook.svg") center no-repeat;
    }
  }
  &__instagram {
    @extend .social-icon;
    background: url("/img/instagram.svg") center no-repeat;
    
    &:hover {
      background: url("/img/roinstagram.svg") center no-repeat;
    }
  }
  &__twitter {
    @extend .social-icon;
    background: url("/img/twitter.svg") center no-repeat;
    
    &:hover {
      background: url("/img/btwitter.svg") center no-repeat;
    }
  }
  &__youtube {
    @extend .social-icon;
    margin-left: -4px;
    width: 42.5px;
    background: url("/img/cloudinary.svg") center no-repeat;
    
    &:hover {
      background: url("/img/ccloudinary.svg") center no-repeat;
    }

    // &::hover {
    //   background: url("");
    // }
  }

  &--realisation {
    > div {
      filter: brightness(5);
    }
  }
}

.realisation {
  padding-top: 10%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
  justify-content: center;
  text-align: left;

  @media #{$medium} {
    max-width: 550px;
    justify-content: center;
  }

  @media #{$small} {
    max-width: 350px;
  }



  &__caption {
    font-size: 20px !important;
    flex-grow: 1;
    flex-basis: 100%;
    display: flex;
    margin-left: 1%;

    h1 {
      white-space: nowrap;

      @media #{$medium} {
        white-space: normal;
        width: auto;
        font-size: $third-header-size;
      }

      @media #{$small} {
        font-size: $second-header-size;
      }
    }
  }

  &__year {
    padding-left: 20%;
    padding-bottom: 5%;
    font-size: $third-header-size;

    @media #{$medium} {
      padding-left: 350px;
      padding-bottom: 20px;
      font-size: $second-header-size;
    }
    @media #{$small} {
      padding-left: 1%;
      padding-right: 3%;
      font-size: $second-header-size;
    }
  }

  &__liflet {
    width: 100%;
    max-width: 500px;
    background-position: center;
    box-sizing: border-box;
    border: 3px solid $main-font-col;
    align-self: flex-start;
      
    @media #{$medium} {
      margin-right: 0;
      margin-bottom: 20px;
    }
    @media #{$small} {
      margin-left: -4%;
    }
  }

  &__budjet {
    display: flex;
    font-size: 27px;
    margin-bottom: 20px;

    @media #{$small} {
      font-size: $second-header-size;
    }
  }

  &__budjet-item {
    /*pricing-item*/
    border-right: 1px solid $main-font-col;
    padding: 0 20px;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
      border-right: none;
    }

    span {
      opacity: 0.8;
    }
    
    @media #{$small} {
      font-size: $second-header-size;
    }
  }

  
  &__en-favoris {
      /*pre-order*/
      font-size: 27px;
  
      @media #{$small} {
        font-size: $second-header-size;
      }
  }

  &__info {
    padding-left: 1%;

    @media #{$large} {
      padding-top: 3%;
    }

    @media #{$small} {
      padding-right: 3%;
      max-width: 100%;
    }

  }

    &__selection {
      width: 50%;
      text-align: left;
      padding-top: 2%;
      padding-bottom: 2%;
      padding-left: 20%;
      background: $sneakyLinear;
      border: 1px solid $link-hover-col;
      max-height: 300px;
      overflow: auto;

      @media #{$small} {
       width: 100%;
      } 
    }

    &__pic {
      font-size: 13px;
      border-bottom: 1px solid $varela-grey;
      font-family: $arial;
      color: $main-back;
      list-style-type: none;
      padding: 20px;
      box-sizing: border-box;

    i {
      display: inline-block;
      background: url("/img/play.svg") center bottom no-repeat;
      width: 13px;
      height: 13px;
      margin-right: 15px;
    }
  }

  &__carroussel {
    background: $header-back;
    margin-bottom: 20px;
    color: $footer-font-col;
  }

  &__trackline-container {
    display: flex;
    margin-bottom: 10px;
  }

  &__trackline {
    flex-grow: 1;
    background: $button-col;
    height: 4px;
    border-radius: 50%;
    margin: 10px 10px 0;
  }

  &__play-buttons {
    display: flex;
  }

  &__prev, &__next {
    width: 21px;
    height: 13px;
    margin-right: 15px;
  }

  &__prev {
    background: url('/img/prev-track.png');
  }

  &__next {
    background: url('/img/next-track.png');
  }
}

.header {
  background: $headerNav-back;
  height: 83px;
  position: relative;
  z-index: 10;

  .logo {
    margin-top: 15px;
    width: 138px;
    background-size: 100%;
    margin-right: 85px;

    @media #{$small} {
      width: 120px;
      margin: 25 0;
    }
    
    @media #{$medium} {
      width: 120px;
      margin: 27 0;
    }

  }
  
  &__logo-link {

    @media #{$small} {
      width: 90% !important;
    }

    @media #{$medium} {
      width: 95%;
    }

  }

  &__menu {
    padding-top: 30px !important;
    color: $link-hover-col;
    text-align: right;
    z-index: 11;
    display: none !important;

    @media #{$medium} {
      display: block !important;
    }

    @media #{$small} {
      display: block !important;
    }
  }
}

.sidebar {
  position: fixed;
  top: 4.23em;
  bottom: 0;
  right: 0;
  width: 60%;
  z-index: 10;
  font-size: $second-header-size;

  @media #{$medium} {
    display: none;
  }
}

.vidTitle {
  position: absolute;
  margin-left: 30%;
  margin-top: -9.5%;
  font-size: $caption-size;
  color: $main-font-col;
  z-index: 1;

  @media #{$small} {
    text-align: center;
    font-size: 24px;
    margin-left: 5%;
    margin-top: -25%;
  }
}

.storyVid{
  text-align: center;
  padding-top: 8%;

  @media #{$large} {
    padding-top: 16.8vh;
    padding-bottom: 10vh;
    width: 100%;
  }

  @media #{$medium} {
    padding-top: 12vh;
    margin-left: -1em;
  }
}


.landing {
  color: $main-font-col;
 
  @media #{$large}{
    margin-top: 5%;
  }

  @media #{$small} {
    margin-inline-start: 5%; 
    margin-top: 5%;  
  }

  &__template-container {
    margin-left: -1em;
    align-self: flex-start;
  }

  &__template {
    width: 100%;
    border: 1px solid $link-hover-col;
  }
}

.wave-top {
  background-color: hsl(0, 0%, 19%);
  position: relative;
  top: 0;
  left: 0;
  width: 60%;
  border-top: 1px solid $link-hover-col;
  border-right: 1px solid $link-hover-col;
  border-left: 1px solid $link-hover-col;
  z-index: 1;

  @media #{$small} {
    height: 8em;

  }
}

.varela-container {
  width: 90%;
	margin: auto;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 2%;

  @media #{$medium} {
    width: 95%;
    grid-template-columns: repeat(1, 1fr) !important;
  }  
}

.modalTitle {
  padding-bottom: 5%;
  color: #f8f9fa !important;
}

.vlogo {
    margin-left: -1%;
}

.varela-hero-img {
  @media #{$medium} {
    margin-left: -5.5%;
  }   
}

.vrtext {
  margin-top: 5%;

  p {
    color: #f8f9fa !important;
    font-size: 20px;

    &::before {
      content: "" !important;
    }
  }
}

.vrtext1 {
  margin-top: 5%;
  text-align: left;
  justify-content: left;

  p {
    color: #f8f9fa !important;
    font-size: 20px;

    &::before {
      content: "" !important;
    }
  }
}

.jacksonPic {
  width: 100%;

  @media #{$medium} {
    margin-left: -2%;
  }
}


.varela-hero1-img {
  padding-top: 5%;
  
  @media #{$medium} {
    padding-top: 50%;
    margin-right: 5%;
  }
}

.modalTitle1 {
  padding-top: 15%;
  text-align: center;
  padding-bottom: 2%;
  color: #f8f9fa !important;

  @media #{$medium} {
    margin-top: -40%;
  }
}

.varela-map {
  width: 100%;
  grid-template-columns: repeat(1, 1fr) !important;

  @media #{$medium} {
    margin-left: -6%;
    width: 111.5%;
    padding-top: 60%;
  }   
}

.vrtext2 {
  margin-top: 5%;
  text-align: center;
  justify-content: center;

  p {
    color: #f8f9fa !important;
    font-size: 20px;

    &::before {
      content: "" !important;
    }
  }
}

.varela {
  max-width: 1088;

  &__year {
    text-align: center;
    font-size: $caption-size;
    position: relative;
    margin-bottom: 100px;
    margin-top: 100px;

    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      height: 50px;
      width: 1px;
      left: 50%;
      border-left: 1px dotted $main-font-col;
    }

    &::before{
      top: -70px;
    }
    &::after {
      bottom: -70px;
    }

  }

  &__item:first-of-type {
    .varela__year {
       margin-top: 0;
       &::before {
         display: none;
       }
    }
  }

  &__img {
    margin-bottom: 20px;
      width: 100%;
  }

  &__title {
    font-size: $second-header-size;
    margin-bottom: 13px;
  }

  &__text {
    font-family: $arial;
    font-size: 17px;
  }
}

.folio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
   display: flex;
   flex-basis: calc(33.3% - 30px);
   margin-bottom: 60px;
   border-right: 1px dotted #fff;
   box-sizing: border-box;
   padding: 20px 0px 10px 0;
   
   @media #{$small} {
     border: none;
   }

   &:nth-of-type {
     border-right: 100px;
   }
  }

  &__pic {
   font-size: 45px;
   line-height: 100px;
   color: #fafafa;
  }
  
  &__month {
    font-size: 20px;
    text-transform: uppercase;
    color: $link-hover-col;
  }

  &__details {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }

  &__location {
    flex-grow: 1;
    font-size: 32px;
    color: cornflowerblue;
  }
    
}

.info {
  width: 100%;

  h2 {
    margin-bottom: 28px;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__item {
    margin-bottom: 60px;

    &--consulted {
      opacity: .6;

      .prim-button {
        background: #c11111;
      }

      .info__details {
        pointer-events: none;
      }
    }
  }

  &__name {
    font-size: $third-header-size;
    text-transform: uppercase;
    opacity: .8;
  }

  &__price {
    font-size: $third-header-size;
    margin-bottom: 18px;
  }

  &__details {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__size-error {
    position: absolute;
    top: -50px;
    background: $main-font-col;
    color: $main-back;
    font-family: $arial;
    padding: 10px 20px;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      border-style: solid;
      border-width: 8px;
      border-color: $main-font-col transparent transparent transparent;
      bottom: -16px;
      left: 45%;
    }
  }

  &__img {
    max-width: 367px;
    width: 100%;
  }
}

.sizes, .amount {
  font-family: $arial;
  margin-bottom: 30px;

  span {
    display: inline-block;
    border: 1px solid $main-font-col;
    padding: 5px;
    min-width: 20px;
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__change {
    cursor: pointer;
  }
}

.sizes span {
  cursor: pointer;

  &.selected{
    background: $main-font-col;
    color: $main-back;
  }
}

// contact app
.contact {
  input:focus, input:invalid {
    outline: none;
    box-shadow: none;
  }
  
  .form-container {
    height: 100vh;
    width: 100vw;
  }
  
  .form-container form{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width:100%;
    height: 100%;
  }
  
  .form-section {
    position: relative;
    width: 50%;
    height: 4rem;
    margin-top: 1rem;
    overflow: hidden;
  }
  
  .form-section input {
    position: absolute;
    font-size: 1.5rem;
    color: #eee;
    height: 100%;
    width: 100%;
    background: none;
  /*   background: rgba(0,0,0,0.2); */
    outline: none;
    border: none;
    border-bottom: 1px solid white;
    padding-top: 2rem;
  }
  
  .form-label {
    position: absolute;
    font-size: 1.5rem;
    color: grey;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
  
  .form-label::after {
    position: absolute;
    content: '';
    border-bottom: 3px solid $varela-blue;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    transform: translate(-100%,0);
    
  }
  
  .label-text {
    position: absolute;
    bottom: 0;
  }
  
  .contact-input:focus + .form-label .label-text, .contact-input:not(:placeholder-shown) + .form-label .label-text {
    transform: translateY(-2rem);
    transition: all 250ms ease;
    color: $varela-blue;
    font-size: 1rem;
  }
  
  .contact-input:focus + .form-label::after, .contact-input:not(:placeholder-shown) + .form-section::after {
    transform: translate(0,0);
    transition: transform 250ms ease-in;
  }
}

.VFLogo {
  text-align: center;
  padding-bottom: 2em;
}

.Post {
  max-width: 800px;
  width: 100%;
  display: table;
  margin: 0 auto;
  }

.text-center {
  padding-top: 5px;
  text-align: center;
  font-size: $main-font-col;
  position: inherit;
  border-radius: 2px;
}
